<template>
    <div class="home-container">
        <div class="sections-menu">
            <span 
                class="menu-point"
                :class="{ active: isActiveSection(index) }"
                @click="scrollToSection(index)"
                v-for="(offset, index) in offsets" :key="index"
                :title="'Go to section ' + (index + 1)"
            >
            </span>
        </div>

        <div class="home-section">
          <section class="home-intro">
            <ImgSlide />
            <div class="home-intro-container">
              <div class="home-text">
                <p class="home-text-title">Reasonable Price</p>
                <p class="home-text-content">제작공장과 클라이언트 사이의</p>
                <p class="home-text-content">복잡한 커뮤니케이션은 이제 그만,</p>
                <p class="home-text-content">저희 PROTTYPE.은 10년 이상의 OEM 노하우를 가진</p>
                <p class="home-text-content">MARUGLOBAL의 MOCKUP 제작 브랜드로</p>
                <p class="home-text-content">항상 합리적이고 최고의 결과물을 만들어 드립니다.</p>
              </div>
              <div class="home-request">
                <router-link :to="{name: 'UserRequest'}">
                  문의하러 바로가기
                </router-link>
              </div>
            </div>
          </section>
          <section class="home-aboutUs">
            <div class="home-aboutUs-container">
              <div>
                <p class="home-aboutUs-headline">회사소개</p>
                <p class="home-aboutUs-headline-sub">
                  <span class="home-aboutUs-headline-sub-highlight">더</span>&nbsp;
                  <span class="highlight-onehalf">정교하게</span> &nbsp;
                  <span class="home-aboutUs-headline-sub-highlight">더</span>&nbsp;
                  <span class="highlight-onehalf">Professional</span> 하게
                </p>
                <p class="home-aboutUs-headline-sub">당신이 원하는 최적의 솔루션을 약속드립니다</p>
              </div>
              <div class="home-aboutUs-boxes">
                <div class="home-aboutUs-box">
                  <img src="@/assets/solution.png">
                  <p class="home-aboutUs-title">
                    Prottype만의 전문 솔루션
                  </p>
                  <p class="home-aboutUs-content">
                    전문가와 함께 견적의뢰 부터 완성까지 함께 하며<br>
                    당신의 꿈을 출력해보세요
                  </p>
                </div>
                <div class="home-aboutUs-box">
                  <img src="@/assets/3dprinting.png">
                  <p class="home-aboutUs-title">
                    SLA 방식을 통한 깔끔한 프린팅
                  </p>
                  <p class="home-aboutUs-content">
                    레이저 조사를 통해 쌓아올려 적층면 없이 매끈한 출력<br>
                    최적의 결과물을 보장합니다
                  </p>
                </div>
                <div class="home-aboutUs-box">
                  <img src="@/assets/shipping.png">
                  <p class="home-aboutUs-title">
                    안전하고 신속한 배송
                  </p>
                  <p class="home-aboutUs-content">
                    꼼꼼한 포장으로 안전하게 배송지까지 도착합니다
                  </p>
                </div>
              </div>
            </div>

          </section>
          <section class="home-portfolio">
            <div class="home-aboutUs-container">
              <div>
                <p class="home-portfolio-headline">포트폴리오</p>
                <p class="home-aboutUs-headline-sub">
                  <img class="home-portfolio-logo" src="@/assets/prottypeLogoNavi.svg">
                  &nbsp;만의 포트폴리오를 살펴 보실 수 있습니다.&nbsp;
                    <router-link class="home-portfolio-button" :to="{name: 'UserPortfolio'}">Click More</router-link>
                </p>
                
              </div>
              <ImgSlideT />
            </div>
          </section>
          <section class="home-notice">
            <div class="home-notice-wraper">
              <div class="home-notice-container">
              
                <p class="home-portfolio-headline">공지사항</p>
                <p class="home-aboutUs-headline-sub">새로운 소식을 확인해보세요</p>
              
                <HomeNotice />
                <router-link class="home-notice-button" :to="{name: 'UserNotice'}">Show Me More</router-link>
              </div>
            </div>
          </section>
          <!--<section class="home-extra">
          
          </section> -->
        </div>

    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, nextTick } from 'vue';
import ImgSlide from '@/components/imgSlide/imgSlide.vue';
import ImgSlideT from '@/components/imgSlide/imgSlideT.vue';
import store from '@/store';
import HomeNotice from '@/components/homeNotice/homeNotice.vue';
import { onBeforeRouteLeave } from 'vue-router';
export default {
  components:{
    ImgSlide,
    ImgSlideT,
    HomeNotice
  },
    setup(){

        const inMove = ref(false);
        const inMoveDelay = 400;
        const activeSection = ref(0); // 현재 활성 섹션의 인덱스를 저장
        const offsets = ref([]);
        const backgroundImg = ref(require('@/assets/homebackground.jpg'));

        const calculateSectionOffsets = () => {
            const sections = document.getElementsByTagName('section');
            offsets.value = Array.from(sections).map(section => section.offsetTop);
        };

        const isActiveSection = (index) => {
            return activeSection.value === index;
        };

        const handleMouseWheel = (e) => {

            if (e.deltaY < 0 && !inMove.value) {

              moveUp();
            
            } else if (e.deltaY > 0 && !inMove.value) {

              moveDown();
            }

            // e.preventDefault(); // 이벤트 버블링 방지, 완성후에도 문제 없으면 삭제 예정

            // return false; // 이벤트 버블링 방지, 완성후에도 문제 없으면 삭제 예정
        };

        const moveUp = () => {

            inMove.value = true;
            activeSection.value--;
            
            if (activeSection.value < 0){

              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
              })
            }

            if (activeSection.value < -1) {

              activeSection.value = offsets.value.length - 1;
            }

            scrollToSection(activeSection.value, true);

        };

        const moveDown = () => {
            
            inMove.value = true;
            activeSection.value++;
            
            if (activeSection.value == offsets.value.length) {

              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
              })
            }

            if (activeSection.value > offsets.value.length) {
              activeSection.value = 0;
            }
            
            scrollToSection(activeSection.value, true);
        };

        /*
			    실제 스크롤을 이동 시키는 함수 입니다.
			    @param {id} 활성화시킬(보여줄) section index
			    @param {force} 스크롤 이벤트 강제수행 flag, false로 초기화
		    */
        const scrollToSection = (id, force = false) => {
            
            if (inMove.value && !force) {
                return;   
            }

            activeSection.value = id;
            inMove.value = true;    
            
            nextTick(() => {
                const section = document.getElementsByTagName('section')[id];
                if (section) {
                    section.scrollIntoView({ behavior: 'smooth' });
                    saveCurrentSection();
                }
                
                setTimeout(() => {
                    inMove.value = false;
                }, inMoveDelay);
            });
        };

        /* 현재 섹션을 VUEX에 저장합니다. 네비게이션 바 동적 변환에 활용됩니다. */
        const saveCurrentSection = () => {
          store.commit("currentHomeSection", activeSection.value);
        }

        onBeforeRouteLeave((to, from, next) => {

          // home.vue 페이지에서 나왔을 때 스크롤을 맨위로 올린 뒤 활성화
          window.scrollTo(0, 0);
          document.body.style.overflowY = 'auto';
          next(); // router 이동

        })

        onMounted(() => {

          // 스크롤형 페이지 전환을 위한 css
          document.body.style.overflowY = 'hidden';

          calculateSectionOffsets();
          window.addEventListener('mousewheel', handleMouseWheel, { passive: false }); // Other browsers

          // 배경이미지
          document.documentElement.style.setProperty('--background-image-url', `url(${backgroundImg.value})`);
        });

        onUnmounted(() => {

          window.removeEventListener('mousewheel', handleMouseWheel, { passive: false }); // Other browsers
          store.commit("currentHomeSection", null);
          window.scrollTo(0, 0); // home.vue 페이지에서 나오기전 스크롤을 맨위로 올리기
          document.body.style.overflowY = 'auto'; // home.vue 페이지에서 나왔을 때 스크롤 활성화
        });



        return{

            offsets,
            isActiveSection,
            scrollToSection,

        }
    }
}
</script>

<style scoped>
@import '@/assets/css/home/home.css';
</style>